import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../pli/pli-dialog';
import { Task } from '@lit/task';
import {
    GetAlert_Result,
    getCustomerCaseList,
    GetCustomerCaseList_ItemResult,
    GetCustomerCaseListResponse,
} from 'tms-client';
import { consume } from '@lit/context';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import '../../pli/pli-pager';
import '../../pli/pli-table';
import { defineHeaderItems } from '../../pli/pli-table';
import '../../pli/pli-user-bubble-list';
import { PliUserBubbleProps } from 'pli/pli-user-bubble';
import { when } from 'lit/directives/when.js';

@customElement('add-to-case-dialog')
export class AddToCaseDialog extends LitElement {
    @property({ type: String })
    customerId: GetAlert_Result['customerId'];

    @consume({ context: errorDialogContext })
    errorDialog: ErrorDialogController;

    @state()
    selectedCaseId: GetCustomerCaseList_ItemResult['caseId'] = null;

    _task = new Task(this, {
        task: async ([customerId]) => {
            const { data, error } = await getCustomerCaseList({
                path: { id: customerId },
                query: { status: ['Investigating'], sortField: 'Created' },
            });
            if (error) {
                return this.errorDialog.showError({ title: 'Could not get cases for customer' });
            }

            this.data = data;
        },
        args: () => [this.customerId] as const,
    });

    headerItems = defineHeaderItems({
        ['']: { sortField: null, columnSpan: 1 },
        Id: { sortField: null, columnSpan: 1 },
        ['Case name']: { sortField: null, columnSpan: 4 },
        Date: { sortField: null, columnSpan: 2 },
        Assigned: { sortField: null, columnSpan: 4 },
    });

    data: GetCustomerCaseListResponse = null;

    get hasCases() {
        return this.data.list.length > 0;
    }

    handleChange(caseId: GetCustomerCaseList_ItemResult['caseId']) {
        this.selectedCaseId = caseId;
    }

    handlePrimaryDismiss() {
        const _event: AddAlertToCaseEvent = new CustomEvent('add-alert-to-case', {
            composed: true,
            detail: { caseId: this.selectedCaseId },
        });
        this.dispatchEvent(_event);
    }

    static styles = [
        css`
            label {
                display: block;
            }
        `,
    ];

    render() {
        if (!this.data) {
            return null;
        }

        const renderItem = (item: GetCustomerCaseList_ItemResult): TemplateResult => {
            return html`<tr @click="${() => this.handleChange(item.caseId)}">
                <td>
                    <label for="${item.caseId}"
                        ><input
                            @change="${() => this.handleChange(item.caseId)}"
                            .checked="${this.selectedCaseId === item.caseId}"
                            type="radio"
                            id="${item.caseId}"
                            name="case-to-add"
                    /></label>
                </td>
                <td>
                    <pli-text>${item.caseId}</pli-text>
                </td>
                <td>
                    <pli-text>${item.name}</pli-text>
                </td>
                <td></td>
                <td>
                    <pli-user-bubble-list .items="${item.assigned as PliUserBubbleProps[]}"></pli-user-bubble-list>
                </td>
            </tr> `;
        };

        return html`<pli-dialog modalWidth="md" @primary-dismiss="${this.handlePrimaryDismiss}">
            <pli-dialog-open-button slot="open-button">Add to case</pli-dialog-open-button>
            <pli-dialog-content>
                <pli-icon-box name="exclamation-triangle"></pli-icon-box>
                <pli-text variant="h3">Add alert to an open case</pli-text>

                ${when(
                    this.hasCases,
                    () => html`
                        <pli-text>Choose an open case to add the alert to.</pli-text>
                        <pli-pager .items="${this.data.list}" total="${this.data.total}">
                            <pli-table
                                .items="${this.data.list}"
                                .headerItems="${this.headerItems}"
                                .renderTemplate="${renderItem}"
                            ></pli-table>
                        </pli-pager>
                    `,
                    () =>
                        html`<pli-text><strong>There are not any open cases </strong></pli-text>
                            <pli-text
                                >There are not any open cases for this customer. You can escalate this alert to keep
                                investigating.
                            </pli-text>`,
                )}
            </pli-dialog-content>
            ${when(
                this.hasCases,
                () =>
                    html`<pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
                        <pli-dialog-primary-button slot="primary-button">Confirm</pli-dialog-primary-button>`,
                () => html`<pli-dialog-close-button slot="secondary-button">Ok</pli-dialog-close-button>`,
            )}
        </pli-dialog>`;
    }
}

export type AddAlertToCaseEvent = CustomEvent<{ caseId: GetCustomerCaseList_ItemResult['caseId'] }>;
