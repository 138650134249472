import { bindable, bindingMode } from 'aurelia-framework';

export class AlertStatus {
    @bindable status: string;

    color: string;
    text: string;

    statuses = {
        New: {
            color: 'new',
            text: 'New',
        },
        Opened: {
            color: 'opened',
            text: 'Opened',
        },
        Escalated: {
            color: 'escalated',
            text: 'Escalated',
        },
        Closed: {
            color: 'closed',
            text: 'Closed',
        },
        FalsePositive: {
            color: 'false-positive',
            text: 'False Positive',
        },
    };

    statusChanged(value: string) {
        if (value === undefined || value === null) {
            return;
        }

        let status = this.statuses[value];

        if (status) {
            this.color = status.color;
            this.text = status.text;
        }
    }
}
