import { html, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../pli/pli-dialog';
import '../../pli/pli-input';
import '../../pli/pli-textarea';
import '../../pli/pli-alert-box';

import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { z } from 'zod';
import { ValidationController } from 'controllers/validation-controller';
import { PliInputState } from 'pli/types/input-state';
import { when } from 'lit/directives/when.js';
import '../../shared/status/displayed-status-change';
import { styles } from 'pli/styles';

const schema = z.object({
    name: z.string().min(1, 'Case name is required'),
    description: z.string().optional(),
});

type EscalateAlertPayload = z.infer<typeof schema>;

@customElement('escalate-alert-dialog')
class EscalateAlertDialog extends LitElement {
    static styles = [styles.grid, styles.flex];
    @property({ type: Number })
    alertId: number;

    @state()
    caseName = '';
    @state()
    caseDescription = '';

    schema = schema;

    validationController = new ValidationController(this, this.schema);

    onCaseNameChange(event: PliInputChangeEvent) {
        this.caseName = event.detail.value;
        this.validationController.parse({ name: this.caseName });
    }

    onCaseDescriptionChange(event: PliInputChangeEvent) {
        this.caseDescription = event.detail.value;
    }

    handlePrimary() {
        const { isValid } = this.validationController.parse({ name: this.caseName });
        if (!isValid) {
            return;
        }

        const _event: EscalateAlertEvent = new CustomEvent('escalate', {
            detail: { name: this.caseName, description: this.caseDescription },
        });

        this.dispatchEvent(_event);
    }

    get issues() {
        return this.validationController.issues;
    }

    get hasIssues() {
        return this.validationController.issues.length > 0;
    }

    get isPrimaryButtonEnabled() {
        return Boolean(this.caseName) && !this.hasIssues;
    }

    render() {
        const caseNameInputState: PliInputState = this.hasIssues ? 'invalid' : 'valid';

        return html`<pli-dialog modalWidth="sm" gradient="hidden">
            <pli-dialog-open-button slot="open-button">Escalate alert</pli-dialog-open-button>

            <pli-dialog-content>
                <pli-icon-box name="folder-plus" slot="icon"></pli-icon-box>
                <pli-text variant="h3">Escalate alert</pli-text>
                <pli-text>You are about to escalate an alert and create a case.</pli-text>
                <div class="grid-vertical gap-1">
                    <pli-text><strong>Case name</strong></pli-text>
                    <pli-input state="${caseNameInputState}" @change="${this.onCaseNameChange}"></pli-input>
                    ${when(
                        this.hasIssues,
                        () => html`<pli-alert-box variant="error">${this.issues[0].message}</pli-alert-box>`,
                    )}
                    <pli-text><strong>Case description (Optional)</strong></pli-text>
                    <pli-textarea @change="${this.onCaseDescriptionChange}"></pli-textarea>
                    <displayed-status-change type="alert" from="Opened" to="Escalated"></displayed-status-change>
                </div>
            </pli-dialog-content>

            <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
            <pli-dialog-primary-button
                @pli-dialog-primary-dismiss="${this.handlePrimary}"
                .disabled="${!this.isPrimaryButtonEnabled}"
                slot="primary-button"
                >Escalate and create case</pli-dialog-primary-button
            >
        </pli-dialog>`;
    }
}

export type EscalateAlertEvent = CustomEvent<EscalateAlertPayload>;
