import { LitElement, html, css } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import '../../pli/pli-dialog';
import { AlertStatus, DismissAlert_Request, DismissReasonType } from 'tms-client';
import '../../shared/status/displayed-status-change';
import { styles } from 'pli/styles';
import '../../pli/pli-textarea';
import { PliStatusLabelVariant } from 'pli/pli-status-label';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import '../../pli/pli-radio-item-group';
import { PliRadioItemGroupUpdatedEvent } from '../../pli/pli-radio-item-group';

type DismissAlertType = Extract<DismissReasonType, 'FalsePositive' | 'Closed'>;

@customElement('dismiss-alert-dialog')
export class DismissAlertDialog extends LitElement {
    static styles = [
        styles.grid,
        styles.flex,
        css`
            :host {
                --border-color: var(--button-background-primary);
                --active-shadow: inset 0 0 0 var(--size-0-25) var(--border-color), var(--input-border-focus-shadow);
                --default-shadow: inset 0 0 0 var(--size-0-25) var(--border-color);
                --outer-shadow: 0 0 0 var(--radius-0-25) rgba(0, 0, 0, 0.5);
            }

            .radio-item-group {
                padding: var(--size-1);
                background-color: var(--radio-group-background);
                border-radius: var(--radius-sm);
            }

            label {
                position: relative;
            }

            label:has(input:focus) .custom-radio-icon {
                box-shadow: var(--active-shadow);
            }

            input[type='radio'] {
                position: absolute;
                opacity: 0;
            }

            .custom-radio-icon {
                width: var(--size-1);
                height: var(--size-1);
                border-radius: 50%;
                border: var(--border-default);
            }

            .custom-radio-icon.active {
                box-shadow: inset 0 0 0 var(--size-0-25) var(--border-color); /* Inset shadow to simulate border */
                border-color: var(--border-color);
            }
        `,
    ];

    @state()
    dismissReason: DismissAlertType = 'FalsePositive';

    @state()
    dismissReasonComment = '';

    handlePrimary() {
        const _event: DismissAlertEvent = new CustomEvent('dismiss', {
            composed: true,
            detail: { reason: this.dismissReason, comment: this.dismissReasonComment },
        });
        this.dispatchEvent(_event);
    }

    handleChange(event: PliRadioItemGroupUpdatedEvent) {
        const { selected } = event.detail;
        this.dismissReason = selected as DismissAlertType;
    }

    isRadioItemActive(value: AlertStatus) {
        return value === this.dismissReason;
    }

    render() {
        const radioItems: { text: string; value: AlertStatus }[] = [
            { text: 'Dismiss as False Positive', value: 'FalsePositive' },
            { text: 'Dismiss as Closed', value: 'Closed' },
        ];

        const dismissReasonMap: Record<DismissAlertType, PliStatusLabelVariant> = {
            Closed: 'Closed',
            FalsePositive: 'FalsePositive',
        };

        return html`<pli-dialog modalWidth="sm" gradient="hidden">
            <pli-dialog-open-button variant="destructive" slot="open-button">Dismiss alert</pli-dialog-open-button>

            <pli-dialog-content>
                <pli-icon-box name="folder-plus" slot="icon"></pli-icon-box>
                <pli-text variant="h3">Dismiss an alert</pli-text>
                <pli-text>You are about to dismiss this alert.</pli-text>
                <pli-radio-item-group .items="${radioItems}" @change="${this.handleChange}"></pli-radio-item-group>

                <pli-text>Reason for dismissing alert</pli-text>
                <pli-textarea
                    placeholder="Leave your comment here"
                    value="${this.dismissReasonComment}"
                    @change="${(event: PliInputChangeEvent) => (this.dismissReasonComment = event.detail.value)}"
                ></pli-textarea>
                <displayed-status-change
                    type="alert"
                    from="Opened"
                    to="${this.dismissReason}"
                ></displayed-status-change>
            </pli-dialog-content>

            <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
            <pli-dialog-primary-button
                @pli-dialog-primary-dismiss="${this.handlePrimary}"
                variant="destructive"
                slot="primary-button"
                >Dismiss alert</pli-dialog-primary-button
            >
        </pli-dialog>`;
    }
}

export type DismissAlertEvent = CustomEvent<DismissAlert_Request>;
