import { Router, RouterConfiguration, activationStrategy } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';
import { OpenIdConnectRoles } from 'aurelia-open-id-connect';

export class Alerts {
    public router: Router;

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.map([
            {
                route: '',
                name: 'alerts',
                moduleId: PLATFORM.moduleName('./list/alert-list'),
                settings: { roles: [OpenIdConnectRoles.Authenticated] },
                activationStrategy: activationStrategy.replace,
            },
        ]);

        this.router = router;
    }
}
