import { LitElement, html, css } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../pli/pli-dialog';
import { DismissReasonType } from 'tms-client';
import '../../pli/pli-status-label';

@customElement('reinvestigate-alert-dialog')
export class ReinvestigateAlertDialog extends LitElement {
    @property()
    dismissReasonType: DismissReasonType;

    @property()
    dismissReason: string;

    handlePrimaryDismiss() {
        this.dispatchEvent(new CustomEvent('reinvestigate', { composed: true }));
    }

    render() {
        return html`<pli-dialog modalWidth="sm" @primary-dismiss="${this.handlePrimaryDismiss}">
            <pli-dialog-open-button slot="open-button">Reinvestigate</pli-dialog-open-button>
            <pli-dialog-content>
                <pli-text variant="h3">This alert is dismissed</pli-text>
                <pli-text as="p"
                    >This alert has been dismissed by an agent. You cannot make any changes to this alert until you have
                    re-opened it using the Reinvestigate button.
                </pli-text>
                <pli-text as="p"><strong>Dismissed as</strong></pli-text>
                <pli-status-label variant="FalsePositive"></pli-status-label>
                <pli-text as="p"><strong>Reason for Dismissing</strong> </pli-text>
                <pli-text>${this.dismissReason || '-'}</pli-text>
            </pli-dialog-content>
            <pli-dialog-close-button slot="secondary-button">Cancel</pli-dialog-close-button>
            <pli-dialog-primary-button slot="primary-button">Reinvestigate</pli-dialog-primary-button>
        </pli-dialog>`;
    }
}
