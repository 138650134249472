import { LitElement, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';

import 'pli/pli-text';
import 'pli/pli-button';
import 'pli/pli-icon';
import 'pli/pli-card';
import 'pli/pli-status-label';
import 'pli/pli-user-bubble-list';
import 'pli/pli-alert-box';

import 'shared/transaction/transaction-history-area';

import { PliUserBubbleListProps } from 'pli/pli-user-bubble-list';

import { Task } from '@lit/task';
import { styles } from 'pli/styles';
import { consume } from '@lit/context';

import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { Router, routerContext } from 'context/router-context';

import { dateTimeFormatter } from 'utils/datetime-formatter';

import {
    addCaseAlert,
    AlertStatus,
    assignAlert,
    createAlertCase,
    dismissAlert,
    getAlert,
    GetAlert_Result,
    getCase,
    GetCase_Result,
    openAlert,
    reinvestigateAlert,
    User,
} from 'tms-client';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import '../../pli/pli-input';
import '../../pli/pli-textarea';
import './escalate-alert-dialog';
import { EscalateAlertEvent } from './escalate-alert-dialog';
import './dismiss-alert-dialog';
import { DismissAlertEvent } from './dismiss-alert-dialog';
import './add-to-case-dialog';
import { AddAlertToCaseEvent } from './add-to-case-dialog';
import '../../components/dialog/assign-dialog';
import { AssignDialogEvent } from '../../components/dialog/assign-dialog';
import './reinvestigate-alert-dialog';

@customElement('alerts-details')
class AlertsDetails extends LitElement {
    @property({ type: Number, reflect: true })
    alertId?: number;

    @consume({ context: routerContext })
    _router?: Router;

    @consume({ context: errorDialogContext })
    errorDialog: ErrorDialogController;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @state()
    _assigned: User[] = [];

    _alertModel: GetAlert_Result = null;
    _caseModel: GetCase_Result = null;

    static styles = [
        styles.base,
        styles.grid,
        styles.form,
        styles.position,
        css`
            :host {
                --button-group-top: var(--size-2-5);
            }
            .button-group {
                top: var(--button-group-top);
                align-self: flex-start;
            }
        `,
    ];

    _task = new Task(this, {
        task: async ([alertId]) => {
            await this.load(alertId);
        },
        args: () => [this.alertId] as const,
    });

    private async load(alertId: number) {
        await this.loadAlert(alertId);

        if (this._alertModel.caseId) {
            await this.loadCase(this._alertModel.caseId);
        }
    }

    private async loadAlert(alertId: number) {
        const { data, error } = await getAlert({ path: { id: alertId } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not get alert' });
        }

        this._alertModel = data;
        this._assigned = this._alertModel.assigned;

        if (this._alertModel.status === 'New') {
            await this.updateStatusNewToOpened();
        }
    }

    private async updateStatusNewToOpened() {
        const { error } = await openAlert({ path: { id: this._alertModel.alertId } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not update alert status' });
        }

        this._alertModel.status = 'Opened';
    }

    private async loadCase(id: GetAlert_Result['caseId']) {
        const { data, error } = await getCase({ path: { id } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not load case' });
        }

        this._caseModel = data;
    }

    _renderClosedFromCaseReinvestigateDialog = () => {
        return html`
            <pli-dialog>
                <pli-dialog-open-button slot="open-button">Reinvestigate</pli-dialog-open-button>

                <pli-dialog-content
                    ><pli-icon-box slot="icon" name="arrow-counterclockwise" color="blue"></pli-icon-box>
                    <pli-text variant="h3">Reinvestigate alert</pli-text>
                    <p>
                        This alert has been closed through a case. In order to reinvestigate this alert, case needs to
                        be re-opened.
                    </p>
                    <p>
                        Case name:
                        <pli-button
                            variant="text"
                            as="a"
                            size="lg"
                            href="customers/${this._alertModel.customerId}/cases/${this._alertModel
                                .caseId}/#investigations"
                        >
                            ${this._caseModel?.name}
                        </pli-button>
                    </p>
                </pli-dialog-content>
                <pli-dialog-close-button slot="secondary-button">Ok</pli-dialog-close-button>
            </pli-dialog>
        `;
    };

    get isDismissed(): boolean {
        const statuses: AlertStatus[] = ['Closed', 'FalsePositive'];
        return statuses.includes(this._alertModel.status);
    }

    get isEscalated(): boolean {
        return this._alertModel.status === 'Escalated';
    }

    get isOpened(): boolean {
        return this._alertModel.status === 'Opened';
    }

    get isNew(): boolean {
        return this._alertModel.status === 'New';
    }

    get isReported(): boolean {
        return this._alertModel.status === 'Reported';
    }

    get reason(): string {
        switch (this._alertModel.status) {
            case 'FalsePositive':
                return 'False Positive';

            case 'Closed':
                return 'Closed';

            default:
                return 'Unknown';
        }
    }

    async onEscalate(event: EscalateAlertEvent) {
        const { detail } = event;

        const { data, error } = await createAlertCase({
            path: { id: this.alertId },
            body: { ...detail },
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not escalate alert' });
        }

        this._router.navigate(`customers/${this._alertModel.customerId}/cases/${data.caseId}/#investigations`);
    }

    async onDismiss(event: DismissAlertEvent) {
        const { detail } = event;

        const { error } = await dismissAlert({ path: { id: this.alertId }, body: { ...detail } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not dismiss alert' });
        }

        this._task.run();
    }

    async onAddAlertToCase(event: AddAlertToCaseEvent) {
        const { caseId } = event.detail;
        const { error } = await addCaseAlert({
            path: { id: caseId },
            body: { alertId: this.alertId },
            parseAs: 'stream',
        });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not add alert to case' });
        }

        await this._task.run();
    }

    async onAssign(event: AssignDialogEvent) {
        const { users } = event.detail;
        const userIds = users.map((i) => i.userId);
        const { error } = await assignAlert({ path: { id: this.alertId }, body: { userIds } });

        if (error) {
            return this.errorDialog.showError({ title: 'Could not assign user / users to alert' });
        }

        this._assigned = [...users];
    }

    async onReinvestigate() {
        const { error } = await reinvestigateAlert({ path: { id: this.alertId } });
        if (error) {
            return this.errorDialog.showError({ title: 'Could not activate reinvestigation of alert' });
        }
        await this._task.run();
    }

    renderStatusLabel(): TemplateResult {
        if (this._alertModel.status) {
            return html`<pli-status-label variant="${this._alertModel.status}"
                >${this._alertModel.status}</pli-status-label
            >`;
        }
    }

    renderDate(): TemplateResult {
        if (this._alertModel.created) {
            return html`${dateTimeFormatter(this._alertModel.created.toString())}`;
        }
    }

    renderAssigned(): TemplateResult {
        if (this._assigned) {
            return html`<pli-user-bubble-list
                .items="${this._assigned as PliUserBubbleListProps['items']}"
            ></pli-user-bubble-list>`;
        }
    }

    renderConclusion(): TemplateResult {
        const investigating = html`<pli-alert-box variant="info" displayMode="text"
            ><pli-icon name="info-circle"></pli-icon>
            <p>This alert is under investigation. A decision has not been made</p></pli-alert-box
        >`;
        const escalated = html`<pli-alert-box variant="success" displayMode="text"
            ><pli-icon name="check2-circle"></pli-icon>
            <div>
                <p><strong>This alert is escalated and is being investigated in case.</strong></p>
                <pli-button
                    variant="text"
                    as="a"
                    size="lg"
                    href="customers/${this._alertModel.customerId}/cases/${this._alertModel.caseId}/#investigations"
                >
                    ${this._caseModel?.name}
                </pli-button>
                <p>${this._caseModel?.description}</p>
            </div></pli-alert-box
        >`;
        const dismissed = html`<pli-alert-box variant="success" displayMode="text"
            ><pli-icon name="check2-circle"></pli-icon>
            <div>
                <p>
                    Alert is dismissed as ${this.reason}
                    ${when(this._alertModel.dismissReason, () => html`with reason: ${this._alertModel.dismissReason}`)}
                </p>
                ${when(
                    this._alertModel.caseId,
                    () =>
                        html` <pli-button
                                variant="text"
                                as="a"
                                size="lg"
                                href="customers/${this._alertModel.customerId}/cases/${this._alertModel
                                    .caseId}/#investigations"
                            >
                                ${this._caseModel?.name}
                            </pli-button>
                            <p>${this._caseModel?.description}</p>`,
                )}
            </div></pli-alert-box
        >`;
        const reported = html`<pli-alert-box variant="success" displayMode="text"
            ><pli-icon name="check2-circle"></pli-icon>
            <div>
                <p>The alert has been submitted to goAML to be reported through report:</p>
                ${when(
                    this._caseModel,
                    () =>
                        html` <pli-button
                            variant="text"
                            as="a"
                            size="lg"
                            href="report-filing/${this._caseModel.reportFilingId}"
                        >
                            ${this._caseModel.reportFilingName}
                        </pli-button>`,
                )}
            </div></pli-alert-box
        >`;

        if (this.isNew || this.isOpened) {
            return investigating;
        } else if (this.isEscalated) {
            return escalated;
        } else if (this.isDismissed) {
            return dismissed;
        } else if (this.isReported) {
            return reported;
        }
    }

    render(): TemplateResult {
        if (!this._alertModel) {
            return null;
        }

        return html`
            <div class="grid-vertical gap-1 relative wrapper">
                <pli-button
                    class="back-button"
                    variant="text"
                    as="a"
                    size="lg"
                    href="customers/${this._alertModel.customerId}"
                >
                    <pli-icon name="arrow-up-circle" slot="icon-left"></pli-icon>
                    Back to Investigations
                </pli-button>
                <pli-text as="h3" variant="h3">Alert ${this.alertId}</pli-text>
                <div class="grid gap-1">
                    <div class="col-span-10">
                        <div class="grid-vertical gap-1">
                            <div class="grid gap-1">
                                <pli-card class="col-span-6">
                                    <div class="grid-vertical gap-1">
                                        <pli-text as="h4" variant="h4">Overview</pli-text>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Status</strong></label>
                                            ${this.renderStatusLabel()}
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Created</strong></label>
                                            ${this.renderDate()}
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <label><strong>Assigned</strong></label>
                                            ${this.renderAssigned()}
                                        </div>
                                    </div>
                                </pli-card>
                                <pli-card class="col-span-6">
                                    <div class="grid-vertical gap-3">
                                        <div class="grid-vertical gap-05">
                                            <pli-text as="h4" variant="h4">Triggered rule</pli-text>
                                            <pli-button
                                                variant="text"
                                                as="a"
                                                size="lg"
                                                href="/rules/${this._alertModel.rule?.ruleId}"
                                            >
                                                ${this._alertModel.rule?.name}
                                            </pli-button>
                                        </div>
                                        <div class="grid-vertical gap-05">
                                            <pli-text as="h4" variant="h4">Rule description</pli-text>
                                            <span>${this._alertModel.rule?.description}</span>
                                        </div>
                                        <div class="grid-vertical">
                                            <pli-text as="h4" variant="h4">Conclusion</pli-text>
                                            ${this.renderConclusion()}
                                        </div>
                                    </div>
                                </pli-card>
                            </div>
                            ${when(
                                this._alertModel.customerId,
                                () =>
                                    html`<transaction-history-area .customerId="${this._alertModel.customerId}">
                                        <pli-text as="h4" variant="h4" slot="header">Transaction History</pli-text>
                                    </transaction-history-area>`,
                            )}
                        </div>
                    </div>
                    <div class="col-span-2 button-group sticky grid-vertical gap-05">
                        ${when(
                            this.isOpened,
                            () =>
                                html`<escalate-alert-dialog @escalate="${this.onEscalate}"></escalate-alert-dialog>
                                    <add-to-case-dialog
                                        @add-alert-to-case="${this.onAddAlertToCase}"
                                        .customerId="${this._alertModel.customerId}"
                                    ></add-to-case-dialog>
                                    <assign-dialog @assign="${this.onAssign}"></assign-dialog>
                                    <dismiss-alert-dialog @dismiss="${this.onDismiss}"></dismiss-alert-dialog>`,
                        )}
                        ${when(
                            this.isDismissed && this._alertModel.dismissReasonType !== 'ClosedThroughCase',
                            () =>
                                html`<reinvestigate-alert-dialog
                                    .dismissReasonType="${this._alertModel.dismissReasonType}"
                                    .dismissReason="${this._alertModel.dismissReason}"
                                    @reinvestigate="${this.onReinvestigate}"
                                ></reinvestigate-alert-dialog>`,
                        )}
                        ${when(this.isDismissed && this._alertModel.dismissReasonType === 'ClosedThroughCase', () =>
                            this._renderClosedFromCaseReinvestigateDialog(),
                        )}
                    </div>
                </div>
            </div>
        `;
    }
}
